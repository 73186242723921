<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
 
        <banner
          title="Projects"
          :breadcrumb="[
             {
              label: 'Dashboard',
            },
           
            {
              label: 'Projects',
            }

          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card">


<div class="card-body">


  <div class="row">
                  <div class="col text font">

                    <h4><strong style="color: #00364f; font-weight: bolder">Projects</strong></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                   
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>
                <!-- start list area  -->







                  <div class="row">
                    <div class="col-md-12 col-xl-12 col-sm-12" >
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr class="text tr-head table-wrapper-scroll-y rounded-circle" style="background-color: white">
                            <th class="text-truncate" style="
                                  background-color: #cdcdcd;
                                  border-radius: 8px 0px 0px 8px;
                                ">
                              Project ID
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Client Name
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Retailer Name
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Business
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Service
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Proff. Fees
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Challan
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Progress
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Professional Status
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th>
                            <th style="
                                  background-color: #cdcdcd;
                                  border-radius: 0px 8px 8px 0px;
                                " class="text-truncate">
                              Action
                            </th>
                          </tr>
                          <tbody class="text">
                            <tr v-for="(clientinfo, index) in clients" :key="index">
                              <td class="text-truncate" v-if="clientinfo">
                                {{ clientinfo.clientServiceNo }}
                              </td>
                              <td class="text-truncate" v-if="clientinfo.client">
                                {{ dateTime(clientinfo.date) }}
                              </td>
                              <td class="text-truncate" v-if="clientinfo.client">
                                {{ clientinfo.client.fname }}
                                {{ clientinfo.client.lname }}
                              </td>
                              <td class="text-truncate" v-if="clientinfo.client">
                                {{ clientinfo.retailer.name }}
                              </td>
                              <td class="text-truncate">
                                <span v-if="clientinfo.business">{{ clientinfo.business.name }}</span>
                              </td>
                              <td class="text-truncate" v-if="clientinfo.service">
                                {{ clientinfo.service.name }}
                              </td>
                              <td class="text-truncate" v-if="clientinfo.service">
                                {{
                                  clientinfo.professionalFee +
                                  clientinfo.professionalFeeGst
                                }}
                              </td>
                              <td class="text-truncate" v-if="clientinfo.client_service_challan">
                                {{
                                  getChallanAmt(clientinfo.client_service_challan)
                                }}
                              </td>

                              <td class="text-truncate">
                                <div class="progress" style="height: 7px">
                                  <div :style="`background-color: #f21300; width: ${progressForProject(
                                    clientinfo.client_service_professional_step
                                  )}%`" class="progress-bar" role="progressbar" aria-valuemax="100"></div>
                                </div>
                              </td>

                              <td style="text-transform: capitalize">
                                <span v-if="clientinfo.client_service_professional"
                                  v-html="statusGetProfessional(clientinfo.client_service_professional)"></span>
                              </td>
                              <td class="text-truncate" style="text-transform: capitalize">
                                <p v-if="clientinfo.status == 'assigned'" class="btn btn-sm" style="
                                      width: 60px;
                                      background-color: #00364f;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                                <p v-if="clientinfo.status == 'completed'" class="btn btn-sm" style="
                                      width: 90px;
                                      background-color: #008000;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>

                                <p v-if="clientinfo.status == 'pending'" class="btn btn-sm" style="
                                      width: 60px;
                                      background-color: #cdcdcd;
                                      color: white;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>New</b>
                                </p>
                                <p v-if="clientinfo.status == 'processed'" class="btn btn-sm" style="
                                      width: 80px;
                                      background-color: #ffaa00;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                                <p v-if="clientinfo.status == 'hold'" class="btn btn-sm" style="
                                      width: 60px;
                                      background-color: #8f8d8e;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                                <p v-if="clientinfo.status == 'rejected'" class="btn btn-sm" style="
                                      width: 60px;
                                      background-color: #00364f;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                                <p v-if="clientinfo.status == 'cancelled'" class="btn btn-sm" style="
                                      width: 80px;
                                      background-color: #f21000;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                                <p v-if="clientinfo.status == 'upgrade'" class="btn btn-sm" style="
                                      width: 60px;
                                      background-color: #ee0def;
                                      color: white;
                                      box-shadow: 0px 0px 5px 0px gray;
                                      border-radius: 50px;
                                      font-size: 10px;
                                      padding: 3px 3px 3px 3px;
                                      margin-top: 5px;
                                      margin-bottom: 5px;
                                    ">
                                  <b>{{ clientinfo.status }}</b>
                                </p>
                              </td>
                              <td class="text-truncate">
                                <div class="btn-group btn-group-sm" role="group" aria-label="...">
                                  <!-- <router-link
                                                                    :to="`update-admins/${admininfo.id}?portal=true`"
                                                                    class="btn btns btn-sm btn-success"
                                                                    style="padding:5px 4.5px 5px 4.5px;">
                                                                    <font-awesome-icon icon="edit" />
                                                                </router-link> -->

                                  <router-link :to="`/professional/project-view/${clientinfo.id}`"
                                    class="btn btns btn-sm text-white" style="
                                        background-color: #00364f;
                                        padding: 5px 4.5px 5px 4.5px;
                                      ">
                                    <font-awesome-icon icon="eye" />
                                  </router-link>

                                  <router-link v-if="statusGetProfessionalBtn(clientinfo.client_service_professional) == 'true'" to="#" data-bs-toggle="modal"
                                    @click="valueAssign(clientinfo,clientinfo.client_service_professional)" data-bs-target="#projectStatusChange"
                                    class="btn btns btn-sm btn-success" style="padding: 5px 4.5px 5px 4.5px">
                                    <font-awesome-icon icon="check-double" />
                                  </router-link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
</div>


             
                      
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClientserviceserviceservices(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClientserviceserviceservices(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClientserviceserviceservices(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClientserviceserviceservices(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClientserviceserviceservices(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClientserviceserviceservices(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClientserviceserviceservices(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClientserviceserviceservices(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClientserviceserviceservices(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClientserviceserviceservices( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClientserviceserviceservices( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClientserviceserviceservices(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClientserviceserviceservices(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClientserviceserviceservices(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClientserviceserviceservices(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClientserviceserviceservices(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="projectStatusChange" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #00364f">
          <h4 class="modal-title text-white" id="exampleModalLabel">
            <strong>Project Status</strong>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col" v-if="this.form.errors">
              <div class="alert alert-danger p-1" role="alert" v-if="this.form.errors.error">
                {{ this.form.errors.error }}
              </div>
            </div>
          </div>
          <form @submit.prevent="statusChangeSave">
      
              <!-- <div v-if="stsCheck == 'assigned'">
                <div class="form-check p-1">
                  <input class="form-check-input" v-model="catchStatus" value="accept" type="radio" name="flexRadioDefault"
                    id="accept" />
                  <label for="accept"> Accept </label>
                </div>

                <div class="form-check p-1">
                  <input  class="form-check-input" v-model="catchStatus" value="decline"  type="radio" name="flexRadioDefault"
                    id="Decline" />
                  <label for="Decline"> Decline </label>
                </div>
              </div>

              <div v-if="stsCheck == 'processed' ||  stsCheck == 'rejected' ">
                <div class="form-check p-1">
                  <input v-model="catchStatus" value="reject" class="form-check-input" type="radio" name="flexRadioDefault"
                    id="flexRadioDefault1" />
                  <label for="flexRadioDefault1"> Reject </label>
                </div>

                <div class="form-check p-1">
                  <input v-model="catchStatus" value="complete" class="form-check-input" type="radio" name="flexRadioDefault"
                    id="complete" />
                  <label for="complete"> Sand For Veryfing </label>
                </div>
              </div> -->



           <div class="row">
            <div class="col-12" style="padding: 5px 5px 5px 5px;">
              <label for="sts">Status</label>
              <select id="sts" v-model="catchStatus" class="form-select" aria-label="Default select example">
  <option selected disabled>Select...</option>
  <option v-if="stsCheck == 'processed' ||  stsCheck == 'rejected' " value="complete">Sand For Veryfing</option>
  <option v-if="stsCheck == 'processed' ||  stsCheck == 'rejected' " value="reject">Reject</option>
  <option v-if="stsCheck == 'assigned'" value="accept">Accept</option>
  <option v-if="stsCheck == 'assigned'" value="decline">Decline</option>
</select>
            </div>
            <div class="col-12"  style="padding: 5px 5px 5px 5px;">
              <label for="Remark">Remark</label>
              <textarea id="Remark"  class="form-control" v-model="form.remark" rows="3"
                placeholder="Remark"></textarea>
            </div>
           </div>

         
      
            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="background-color: #f21300"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>



  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" novalidate onsubmit="return false">
          <div class="modal-header align-items-center mb-1" style="background-color: #00364f">
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white">
              <span class="todo-item-favorite cursor-pointer me-75 text-white"><i data-feather="star"
                  class="font-medium-2"></i></span>
              <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal" stroke-width="3">X</i>
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="clientServiceNo" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="clientServiceNo">
                  <label class="form-check-label" for="clientServiceNo">
                    Code
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="date" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="date">
                  <label class="form-check-label" for="date">
                    Date
                  </label>
                </div>
              </li>
              <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
              <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
              <!-- <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="client_id" class="form-check-input" type="radio" name="flexRadioDefault" id="client_id">
  <label class="form-check-label" for="client_id">
    Client PanNo
  </label>
</div></li> -->

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="status" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="status">
                  <label class="form-check-label" for="status">
                    Status
                  </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="service_id" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="service_id">
                  <label class="form-check-label" for="service_id">
                    Service
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="all">
                  <label class="form-check-label" for="all">
                    All
                  </label>
                </div>
              </li>
            </ul>


            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'"
                class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1"
                placeholder="Enter Value">
              <input type="text" v-if="filterType == 'clientServiceNo'" class="form-control" v-model="filterValue"
                id="exampleFormControlInput1" placeholder="Enter Value">
              <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control"
                v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">




              <select v-model="filterValue" v-if="filterType == 'status'" class="form-select"
                aria-label="Default select example">
                <option selected disabled>Please Select...</option>
                <option value="pending">New</option>
                <option value="assigned">Assign</option>
                <option value="completed">Complete</option>
                <option value="cancelled">Cancelled</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="upgrade">Upgrade</option>

              </select>


              <select v-model="filterValue" v-if="filterType == 'service_id'" class="form-select"
                aria-label="Default select example">
                <option selected disabled>Please Select...</option>
                <option v-for="(service, index) in services" :key="index" :value="service.id">{{ service.name }}</option>


              </select>

            </div>

            <button v-if="filterType != 'client_id'" @click="loadClientserviceserviceservices()" type="button"
              class="btn btn-success btn-sm">Success</button>




          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Spinner from "../../../components/professional/comman/Spinner.vue";
import Banner from "../../../components/professional/comman/Banner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "ProfessionalProjects",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      taskProgress:'',
      services: [],
      filterValue: '',
      filterType: '',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      clients: [],
      catchStatus: "",
      client_service_id: null,
      stsCheck: "",
      form: new Form({
        client_service_id: null,
        remark: "",
        errors: {},
      }),
    };
  },
  methods: {
    valueNullFilter() {
      this.filterValue = "";
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    statusGetProfessionalBtn(professional){
      var curPro  = JSON.parse(localStorage.getItem("userData"));
      let res = professional.find(x => x.professional_id == curPro.id);
      var sts
      if(res.status == 'pending' || res.status == 'processed' || res.status == 'rejected'){
        sts = 'true'
      }else{
        sts = 'false'
      }

      return sts;
    },
    statusGetProfessional(professional) {
      var curPro  = JSON.parse(localStorage.getItem("userData"));
      let res = professional.find(x => x.professional_id == curPro.id);
var status
      if (res.status == 'completed') {
       status = "<p class='btn btn-sm'  style='background-color: #008000; width: 90px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }
      if (res.status == 'assigned') {
       status = "<p class='btn btn-sm'  style='background-color: #00364f; width: 60px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }
      if (res.status == 'pending') {
       status = "<p class='btn btn-sm'  style='background-color: #cdcdcd; width: 60px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>New</p>"

      }
      if (res.status == 'processed') {
       status = "<p class='btn btn-sm'  style='background-color: #ffaa00; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }
      if (res.status == 'rejected') {
       status = "<p class='btn btn-sm'  style='background-color: #000000; width: 60px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }
      if (res.status == 'decline') {
       status = "<p class='btn btn-sm'  style='background-color: #f21000; width: 60px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }
      if (res.status == 'cancelled') {
       status = "<p class='btn btn-sm'  style='background-color: #f21000; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }

      if (res.status == 'finalize') {
       status = "<p class='btn btn-sm'  style='background-color: #430194; width: 80px;   border-radius: 50px;  box-shadow: 0px 0px 5px 0px gray;color: white; margin-bottom: 5px; margin-top: 5px; padding: 3px 3px 3px 3px; font-size: 10px;'>"+res.status+"</p>"

      }




      return status;
    },
    getChallanAmt(Challans) {
      var amt = 0
      console.log(Challans)
      for (var i = 0; i < Challans.length; i++) {
        amt += parseInt(Challans[i].amt)
      }
      return amt
    },

    progressForProject(tasks) {
      var complete = 0;
      for (var i = 0; i < tasks.length; i++) {
        if (tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / tasks.length;

      this.taskProgress = n * complete

      return n * complete;
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClientserviceserviceservices();
    },
    statusNoData(status) {
      // allrecords;
      let num = 0;
      for (var i = 0; i < this.clients.length; i++) {
        if (this.clients[i].status == status) {
          num++;
        }
        // if (this.clients[i].status == "cancelled") {
        //   num++;
        // }
      }
      return num;
    },
    valueAssign(client,professional) {



      console.log(client)
console.log('====================================')
console.log(professional)
console.log('====================================')

this.catchStatus = ""


      this.form = {};
      this.form.errors = {};
      var curPro  = JSON.parse(localStorage.getItem("userData"));
     
      // this.catchStatus = ""
      this.form.client_service_id = client.id;
      let res = professional.find(x => x.professional_id == curPro.id);
      if(client.status =='assigned'){
        this.stsCheck = 'assigned';
      }else{
        this.stsCheck = res.status;
      }
      // if(res.status =='processed'){
      //   this.stsCheck = 'processed';
      // }
      
    },
    // statusChange(status) {
    //   this.catchStatus = status;
    // },
    statusChangeSave() {


      
        var stsVal;
      var q;

      if (this.catchStatus == "accept") {
        // alert("accept");
        stsVal = "Accepted";
        q = "acceptclientservice";
      } else if (this.catchStatus == "reject") {
        // alert("reject");
        stsVal = "Rejected";
        q = "rejectclientservice";
      } else if (this.catchStatus == "complete") {
        // alert("reject");
        stsVal = "sand for veryfication";
        q = "completeclientservice";
      } else if (this.catchStatus == "decline") {
        // alert("decline");
        stsVal = "Declined";
        q = "declineclientservice";
      }else{
        this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: "Please Select One",
          });
      }
      if(this.catchStatus){


        this.loading = true;
      this.$axios
        .post(`professional/${q}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          this.catchStatus = ""
          this.form = {}
          this.loadClientserviceserviceservices();
          // this.loadClientserviceserviceserviceservices();

          toast.success("The Project " + stsVal + " successfully.", {
            autoClose: 1000,
          });
          $("#projectStatusChange").modal("hide");
        })
        .catch((error) => {
          // this.form.errors = error.response.data;
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        })
        .finally(() => (this.loading = false));
      }
   
      





     
    },

    loadClientserviceserviceservices(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {


        if (this.filterType == 'all') {
          this.filterValue = ''
          this.filterType = ''
        }



        if (this.filterValue != "" && this.filterType != "") {
          if (this.filterType == 'date') {
            pageUrl += `professional/clientservice?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `professional/clientservice?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }

        } else {
          pageUrl += `professional/clientservice?per_page=${this.per_page}`;
        }


      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clients = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = "";
        })
        .finally(() => (this.loading = false));
    }, servicesLoad() {

      this.$axios
        .post(`allservice`, {}, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data
          console.log(res.data.data)

        });
    },
    loadPageTitle(){
        document.title =this.$store.state.professionalPageTitles.clientsService
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClientserviceserviceservices();
      this.servicesLoad();
      this.loadPageTitle()
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}



@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}

h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}

input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}

.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}


.completed-status{
  width: 90px;
                                    background-color: #008000 !important;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
}

.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
